$blue: #686de0;
$gray: #9e9e9e;
$green: #6ab04c;
$red: #dc3545;

.patient-pin-container {
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin-container {
  width: 92%;
  height: 82%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5vh 4vh;
  border-radius: 10px;
}

.instructions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.patient-pin-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 4vh;
  line-height: 90px;
  color: #000000;
  margin-bottom: 0px;
}

.enter-pin-message {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 2.6vh;
  margin-top: 0px;
}

.pin-field {
  border: 2px solid $gray;
  border-right: none;
  font-size: 2.5rem;
  height: 5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: color, border, box-shadow, transform;
  width: 5rem;

  &:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
    border-right: 2px solid $gray;
  }

  &:focus {
    border-color: $blue;
    box-shadow: 0 0 0.25rem rgba($blue, 0.5);
    outline: none;

    & + .pin-field {
      border-left-color: $blue;
    }
  }

  &:invalid {
    animation: shake 5 linear 75ms;
    border-color: $red;
    box-shadow: 0 0 0.25rem rgba($red, 0.5);

    & + .pin-field {
      border-left-color: $red;
    }
  }

  &.invalid {
    animation: shake 5 linear 75ms;
    border-color: $red;
    box-shadow: 0 0 0.25rem rgba($red, 0.5);

    & + .pin-field {
      border-left-color: $red;
    }
  }

  &.complete {
    border-color: $green;
    color: $green;

    &[disabled] {
      background: rgba($green, 0.1);
      cursor: not-allowed;
      opacity: 0.5;
    }

    & + .pin-field {
      border-left-color: $green;
    }
  }
}

@keyframes shake {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(5%);
  }
}

/* .next-btn {
  width: 50vh;
  height: 10vh;
  background-color: #003396;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 2.6vw;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.next-btn:hover {
  background-color: #0c2e78;
}

.next-btn[disabled] {
  background-color: #003396;
  opacity: 0.5;
  cursor: not-allowed;
} */

@media screen and (max-width: 820px) {
  .next-btn {
    font-size: 4.2vw; /* Increase font size for iPad */
  }
}
