.physical-exams-container {
    display: flex;
  flex-direction: column;
  padding: 20px;
}

.physical-exams-save-body-part-btn {
  width: 200px;
  padding: 12px;
  align-self: flex-end;
  font-size: 20px;
  background-color: #215ed9;
  border: 0;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  margin-left: 40%;
  }
  
.physical-exams-save-body-part-btn:hover {
  cursor: pointer;
  background-color: #134bbb;
  }

  .physical-exams-save-all-btn {
    width: 200px;
    padding: 12px;
    align-self: flex-end;
    font-size: 20px;
    background-color: #003396;
    border: 0;
    border-radius: 5px;
    color: white;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
    }
    
  .physical-exams-save-all-btn:hover {
    cursor: pointer;
    background-color: #0a307c;
    }

/* .accordion-container {
  background-color: #003396;
  color: white;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.accordion-summary {
  background-color: #003396;
  color: white;
  padding: 16px;
}

.accordion-summary-text {
  font-size: 24px;
  font-weight: bold;
}

.btn-submit-section {
  width: 200px;
  padding: 10px;
  font-size: 24px;
  background-color: #003ebb;
  border: none;
  border-radius: 10px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}

.btn-submit-section:hover {
  background-color: #003396;
} */
