.tabs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto; /* Center the tabs horizontally */
    border: 1px solid black; /* Add a border */
    border-radius: 8px; /* Apply border radius */
    overflow: hidden; /* Hide the overflow of tab content */
  }
  
  .tabs-header {
    display: flex;
  }
  
  .tab-header {
    flex: 1;
    background-color: rgba(87, 130, 214, 0.717);
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border-right: 1px solid black; /* Add right border */
    border-bottom: 1px solid black; /* Add right border */
    color: white;
  }
  
  .tab-header:last-child {
    border-right: none; /* Remove right border for the last tab header */
  }
  
  .tab-header.active {
    background-color: darkblue;
    color: white;
    font-weight: bold;
  }  
  
  .tab-content {
    background-color: white;
    padding: 20px;
  }
  
  .tab-content-text {
    font-size: 18px;
    margin-top: 10px;
  }
  