.body-parts-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.body-part-item {
  flex-basis: 25%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.body-part-item input[type="checkbox"] {
  width: 25px;
  height: 25px;
  margin-right: 17px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.body-part-item label {
  font-size: 16px;
}

.body-parts-submit-btn {
  width: 200px;
    padding: 12px;
    align-self: flex-end;
    font-size: 20px;
    background-color: #003396;
    border: 0;
    border-radius: 5px;
    color: white;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
}

.body-parts-submit-btn:hover {
  cursor: pointer;
  background-color: #0a307c;
}
