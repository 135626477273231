/* ErrorPage.css */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f4f8; /* Light grayish-blue background */
  color: #333; /* Dark gray text color */
}

.error-image {
  max-width: 100%; /* Make sure the image doesn't exceed the container width */
  height: auto; /* Maintain the aspect ratio of the image */
  margin-bottom: 30px; /* Add some space below the image */
}

.error-heading {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}

.error-description {
    margin-top: 0;
    font-size: 20px;
  text-align: center;
  max-width: 600px; /* Limit description width for better readability */
  line-height: 1.6; /* Adjust line height for better spacing */
  margin-bottom: 20px;
}

.error-instructions {
  font-size: 16px;
  text-align: center;
  max-width: 600px; /* Limit description width for better readability */
  line-height: 1.6; /* Adjust line height for better spacing */
  margin-bottom: 30px;
}

.error-instructions strong {
  font-weight: bold;
}

.error-instructions a {
  color: #007bff; /* Blue link color */
  text-decoration: none; /* Remove underlines from links */
}

.error-instructions a:hover {
  text-decoration: underline; /* Add underline on hover for links */
}

.go-back-button,
.error-home-button {
  background-color: #007bff; /* Blue button background color */
  color: white; /* White button text color */
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  margin: 5px;
  cursor: pointer;
  text-decoration: none; /* Remove underlines from links */
  border-radius: 5px;
}

.error-button-container {
    display: flex;
    justify-content: center; /* Optional: To horizontally center the buttons within the container */
    flex-direction: row;
  }
  
  .go-back-button,
  .error-home-button {
    background-color: #003396; /* Blue button background color */
    color: white; /* White button text color */
    border: none;
    padding: 8px 30px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    margin: 5px; /* Optional: Adjust the margin as needed */
  }
  
  .go-back-button:hover,
  .error-home-button:hover {
    /* Use the same hover effect as the provided style */
    background-color: #002277; /* Darker blue on hover */
  }

  .retry-button {
    background-color: #083692; /* Blue button background color */
    color: white; /* White button text color */
    border: none;
    padding: 8px 25px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin: 5px; /* Optional: Adjust the margin as needed */
  } 