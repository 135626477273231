.form-actions-container {
    align-items: center;
    margin: 30px 25px;
  }
  
  .back-button-container {
    margin-bottom: 25px;
  }
  
  .back-button {
    background-color: #003396;
    color: white;
    border: none;
    padding: 7px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
  }
  
  .back-button::before {
    content: "<";
    position: absolute;
    left: 7px;
  }
  
  .back-button span {
    margin-left: left;
  }
  
  /* Add any additional CSS styles you need for the component */
  
  
  .info-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  
  .case-info {
    display: flex;
    flex-direction: column;
  }
  
  .case-id {
    font-size: 17px;
    color: black;
    margin: 0;
  }
  
  .name {
    font-size: 24px;
    color: black;
    margin: 0;
    margin-bottom: 3px;
  }
  
  .actions-button {
    background-color: #ffaa00;
    color: white;
    border: 1px solid black;
    padding: 8px 45px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .actions-menu {
    position: absolute;
    background-color: #d9d9d9;
    padding: 5px 25px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    top: 180px;
    right: 25px;
    width: 200px;
    margin-top: 5px;
    cursor: pointer;
    border: 1px solid black; /* Add a black border */
  }
  
  .action-option {
    margin: 5px 0;
  }
  
  .action-option:hover {
    text-decoration: underline;
  }
  
  
  /* Add any additional CSS styles you need for the component */
  