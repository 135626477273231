.bottom-right-container1 {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .textbox-input {
    border: 2px solid #9f9f9f;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    height: 40px;
    font-size: 18px;
    padding-left: 10px;
  }

  .historian-patient-form-responses-case-id-text {
    color: black;
  }
  
  .btn-submit1 {
    width: 180px;
    height: 40px;
    background-color: #003396;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px; /* Adjust margin as needed */
  }
  

  .bottom-right-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .textbox-input {
    height: 5vh;
    padding: 10px;
    border: 1px solid #ccc;
    border-color: #003396;
    border-width: 0.3vh;
  }
  
  .btn-submit {
    /* padding: 1vh 3vh; */
    margin-left: 1.2vh;
    margin-right: 1.2vh;
    background-color: #003396; /* Changed button color */
    color: white; /* Text color for better visibility */
    border: none; /* Remove border for a cleaner look */
    height: 5vh;
    width: 10vh;
    border-radius: 1vh;
    font-weight: bold;
  }
  
  .btn-submit:hover {
    background-color: #002270; /* Darker color on hover */
  }
  
  
  @media screen and (max-width: 820px) {
    .btn-submit {
      font-size: 20px;
    }

    .textbox-input {
      font-size: 15px;
    }
  }
