* {
  box-sizing: border-box;
}

:root {
  background-color: #efefef;
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
}

.page-container {
  height: 100%;
  width: 100%;
}

.user-info h1 {
  display: inline-block;
  margin-right: 10px; /* optional - add some spacing between the headings */
}
.user-info h1:not(:last-child)::after {
  content: "| ";
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #003396;
  justify-content: space-between;
  padding: 20px 10px;
}
.btn-home {
  color: #fff;
  font-size: large;
  text-decoration: none;
  justify-self: flex-start;
}
.btn-home:hover {
  cursor: pointer;
}
.brand {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: xx-large;
  font-weight: bold;
}
