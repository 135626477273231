.doctor-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .big-rectangle {
    position: relative;
    width: 1087px;
    height: 582px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 80px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .small-rectangle {
    position: absolute;
    top: 143px;
    width: 1087px;
    height: 96px;
    background-color: #003396;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 36px;
    font-weight: bold;
    z-index: 2;
  }
  
  .textbox-container {
    display: flex;
    align-items: center;
    width: 60%;
    margin-top: 30px;
  }
  
  .label {
    width: 25%;
    text-align: right;
    margin-right: 80px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
  }
  
  .textbox {
    width: 75%;
    height: 36px;
    border-radius: 10px;
    border: 2px solid #9f9f9f;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 8px;
    font-size: 20px;
  }
  
  .submit-button {
    width: 60%;
    margin-top: 40px;
    height: 81px;
  
    background-color: #003396;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  
    /* text on button */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
  
    /* new properties for button as a link */
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .submit-button:hover {
    background-color: #0c2e78;
  }
  
  .submit-button[disabled] {
    background-color: #003396;
    opacity: 0.5;
    cursor: not-allowed;
  }
  