.patient-form-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.patient-form-big-rectangle {
  position: relative;
  width: 1327px;
  height: 1182px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 100px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.patient-form-small-rectangle {
  position: absolute;
  top: 162px;
  width: 1327px;
  height: 70px;
  background-color: #003396;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 36px;
  font-weight: bold;
  z-index: 2;
}

.accordion-summary {
  background-color: #003396;
}

.btn-submit-section {
  padding: 11px 16px;
  margin: 10px 10px;
  align-self: flex-end;
  white-space: nowrap;
  font-size: 24px;
  background-color: #003ebb;
  font-weight: 600;
  border: 0;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.btn-submit-section:hover {
  cursor: pointer;
  background-color: #003396;
}

.container-questions {
  display: flex;
  flex-direction: column;
}

.image-style {
  width: 600px;
  height: auto;
}

.doctor-only-questions-submit-btn {
  width: 200px;
  padding: 12px;
  align-self: flex-end;
  font-size: 20px;
  background-color: #215ed9;
  border: 0;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  margin-bottom: 10px;
}

.doctor-only-questions-submit-btn:hover {
  cursor: pointer;
  background-color: #134bbb;
}
