
.access-denied-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: white; /* Set the background color to blue */
    color: #003396; /* Set text color to white */
  }
  
  .access-denied-content {
    text-align: center;
  }
  
  .access-denied-home-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #003396;
    color: white;
    font-size: 18px;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .access-denied-home-button:hover {
    background-color: #001f5d; 
  }
  