.container-pdf {
  display: flex;
  flex-direction: column;
  background-color: #d5d5d5;
  align-items: center;
  justify-content: center;
  height: 815px;
  max-height: 815px;
  width: 100%;
  padding: 10px 5px;
}
.controls-pdf {
  background-color: #525659;
  color: #ffffff;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: center;
  justify-content: center;
}
.controls-pdf p {
  margin: 0;
}
.container-navigation-pdf {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}
.button-navigation-container {
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  grid-column: 2;
  justify-self: center;
}
.button-navigation-pdf {
  background-color: transparent;
  border: none;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.button-navigation-pdf:hover {
  background-color: #6a7075;
  cursor: pointer;
}
.button-navigation-pdf:active {
  background-color: #494c4f;
}
.document-pdf {
  font-weight: 500;
  color: black;
}

.navigation-arrow {
  color: #ffffff;
  stroke-width: 1px;
  height: 75%;
  width: 75%;
}
.navigation-zoom-container {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.navigation-zoom {
  stroke-width: 1px;
  color: white;
  height: 75%;
  width: 75%;
}
.button-retry {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #9ba3aa;
}
.button-retry:hover {
  cursor: pointer;
  background-color: #656a6f;
}
.button-retry:active {
  background-color: #515458;
}

/* 
 "Loading..." ellipsis animation
 */
.loading::after {
  display: inline-block;
  animation: dotty steps(1, end) 1500ms infinite;
  content: "";
}

@keyframes dotty {
  0% {
    content: ".\2008\2008";
  }
  33% {
    content: "..\2008";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".\2008\2008";
  }
}
