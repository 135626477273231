.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #003396;
    justify-content: space-between;
    padding: 20px 10px;
}

.brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: xx-large;
    font-weight: bold;
}

.btn-home {
    color: #fff;
    font-size: large;
    text-decoration: none;
    justify-self: flex-start;
  }

  .btn-home:hover {
    cursor: pointer;
  }

  .nav-bar-log-in-btn {
    color: #fff;
    font-size: large;
    font-weight: bold;
    text-decoration: none;
    justify-self: flex-start;
    background: none; /* Remove background color */
    border: none; /* Remove border */
    padding: 0; /* Remove padding if not needed */
    list-style: none; /* Add this to remove any list-style */
    border: none;
  }

  .nav-bar-log-in-btn {
    cursor: pointer;
  }
  

  .profile-container {
    position: relative;
  }
  
  .profile-image {
    cursor: pointer;
  }

  .pofile-image-image {
    width: 35px; /* Adjust the width as needed */
    height: 35px; /* Adjust the height as needed */
    border-radius: 50%;
    margin-right: 15px;
  }

  .dropdown-content {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1.5px solid rgb(24, 90, 165);
    border-radius: 4px;
    z-index: 999;
    min-width: 200px; /* Set a minimum width for the dropdown */
    max-width: 300px; /* Set a maximum width if needed */
    width: auto; /* Let the width adjust based on content */
  }
  
  
  
  .dropdown-header {
    background-color: rgb(24, 90, 165);
    color: white;
    padding: 10px;
    font-weight: bold;
    word-wrap: break-word; /* Allow content to wrap if needed */
  }
  
  
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: rgb(24, 90, 165);
    font-weight: bold;
  }
  
  .dropdown-item:hover {
    background-color: #eeebeb;
  }
