/* .patient-prescreen-container {
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prescreen-container {
  width: 1150px;
  height: 650px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10px 18px;
  border-radius: 10px;
} */


.patient-prescreen-container {
  /* height: 85vh; */
  margin-top: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient-prescreen-middle-container {
  width: 92%;
  height: 70%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 1.5vh 4vh;
  border-radius: 10px;
  margin: 0 auto;
}

.patient-prescreen-case-info-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.patient-prescreen-centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: center; */
}

.patient-prescreen-back-button-container {
  margin-bottom: 1vh;
  margin-left: 0px;
}

.patient-prescreen-back-button-1 {
  background-color: #003396;
  color: white;
  border: none;
  padding: 1vh 2vh;
  font-size: 1.2vw;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
}

.patient-prescreen-case-id-text {
  color: #000000;
  font-size: 1.3vw;
  margin-top: 1.7vh;
  margin-bottom: 0vh;
}


.patient-prescreen-text{
  font-size: 2.4vw;
  margin-bottom: 5vh;
}

/* .prescreen-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
} */

.patient-prescreen-next-btn {
  width: 80vh;
  height: 10vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
  background-color: #003396;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vw;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.patient-prescreen-next-btn:hover {
  background-color: #0c2e78;
}

.patient-prescreen-next-btn[disabled] {
  background-color: #003396;
  opacity: 0.5;
  cursor: not-allowed;
}


@media screen and (max-width: 820px) {
  .patient-prescreen-container {
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .patient-prescreen-middle-container {
    width: 72%;
    height: 90%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1.5vh 4vh;
    border-radius: 10px;
    margin: 0 auto;
  }

  .patient-prescreen-next-btn {
    font-size: 4.2vw;
    width: 35vh;
    height: 7vh;
  }

  .patient-prescreen-case-info-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .patient-prescreen-back-button-1 {
    font-size: 2.2vw;
    margin-bottom: 0px;
  }

  .patient-prescreen-case-id-text {
    margin-top: 0px;
    font-size: 2.3vw;
    margin-bottom: 2vh;
  }
  
  .patient-prescreen-centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .patient-prescreen-text{
      font-size: 3.4vw;
      margin-bottom: 10vh;
    }

  .patient-prescreen-questions {
    margin-bottom: 10vh;
  }
}





