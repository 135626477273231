.patient-setup-container {
  height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patient-setup-small-rectangle {
  position: relative;
  top: 7%;
  width: 75.5%;
  height: 12%;
  background-color: #003396;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2.5vw;
  font-weight: bold;
  z-index: 2;
}

.patient-setup-big-rectangle {
  top: -5vh;
  position: relative;
  width: 75.5%;
  height: 70%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 9vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.patient-setup-textbox-container {
  display: flex;
  align-items: center;
  width: 60%;
  margin-top: 7vh;
}

.patient-setup-label {
  width: 25%;
  text-align: right;
  margin-right: 3vw;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8vw;
  line-height: 6vh;
}

.patient-setup-textbox {
  width: 75%;
  height: 5vh;
  border-radius: 1.5vh;
  border: 2px solid #9f9f9f;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 1.4vh;
  font-size: 1.5vw;
}

.patient-setup-next-button {
  width: 60%;
  margin-top: 5vh;
  height: 10vh;

  background-color: #003396;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1.6vh;

  /* text on button */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8vw;
  line-height: 3.5vh;
  text-align: center;
  color: #ffffff;

  /* new properties for button as a link */
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.patient-setup-next-button:hover {
  background-color: #0c2e78;
  cursor: pointer;
}

.patient-setup-next-button[disabled] {
  background-color: #003396;
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add a class to the body or the container element behind the modal */
.modal-opened {
  position: relative;
}

/* Create a pseudo-element to cover the viewport */
.modal-opened::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.828
  ); /* Adjust opacity here to control darkness */
  z-index: 1050; /* Ensure it's above the modal content */
}

/* Adjust modal styles */
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
}

.modal-content {
  background-color: #ffffff; /* Change to the desired background color */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #003396; /* Set text color to white */
  color: rgb(0, 0, 0);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5vh;
  border-bottom: 1.5px solid #003396;
  background-color: #003396;
  text-align: center;
  border-radius: 5px;
  border-top: 5px;
}

.modal-title {
  margin: 0;
  font-size: 1.8rem; /* Adjust title font size */
  color: white;
}

.modal-body {
  padding: 4vh;
}

.modal-text-instructions {
  font-size: 1.5rem;
  color: #003396;
  font-weight: 700;
  color: rgb(0, 0, 0);
}

.modal-text {
  font-size: 1.3rem;
  color: rgb(0, 0, 0);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.modal-footer button {
  margin-left: 10px;
}

.patient-setup-modal-label {
  font-weight: 550;
  margin-right: 5px;
}

/* .modal-footer button.btn-secondary {
  background-color: rgba(0, 0, 0, 0.3); 
  border: 1px solid rgba(0, 0, 0, 0.3); 
  color: white; 
}

.modal-footer button.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.5); 
  border: 1px solid rgba(255, 255, 255, 0.5);
} */

.modal-footer button.btn-primary {
  background-color: #003396; /* Adjust button color */
  border: none; /* Remove border */
  color: white; /* Set text color to white */
  padding: 10px 20px; /* Adjust padding */
  border-radius: 15px; /* Adjust border radius */
  font-size: 1.6rem; /* Adjust font size */
  display: inline-block; /* Align horizontally in the middle */
  margin: 0 auto; /* Center horizontally */
}

.modal-footer button.btn-primary:hover {
  background-color: rgba(0, 51, 150, 0.9); /* Adjust hover color */
  cursor: pointer;
}

.close {
  color: white;
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
  background: none; /* Remove background */
}

.close span {
  font-size: 1.5rem;
  border: none; /* Remove border */
}

@media screen and (max-width: 820px) {
  .patient-setup-small-rectangle {
    top: 15%;
    font-size: 4vw;
  }

  .patient-setup-label {
    font-size: 2.8vw;
  }

  .patient-setup-textbox {
    font-size: 2.9vw;
  }

  .patient-setup-next-button {
    height: 8vh;
    font-size: 4vw;
  }
}
