.table-form-container {
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
}
.table-form {
  border-collapse: collapse;
  width: 100%;
}

.table-form th,
.table-form td {
  padding: 8px; /* Adjust the padding as desired */
  border: 1px solid #ccc;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.table-form th {
  background-color: #f2f2f2;
}

.table-form td input {
  width: 100%;
  height: 24px; /* Adjust the height as desired */
  border: none;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
}

.table-form td input:focus {
  outline: none;
}

.table-form td:first-child {
  text-align: left;
  font-weight: normal;
}

/* Assuming this is your button styling for the "Autofill" button */

.table-form-button-container {
  margin-top: 1.5vh;
  margin-left: 1vw;
}

.table-form-button-container button.table-form-autofill-btn {
  background-color: rgb(24, 154, 82); 
  color: white; /* Set the text color to white */
  padding: 10px 20px; /* Adjust the padding to make the button bigger */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Add some border-radius for a rounded appearance */
  cursor: pointer; /* Add a pointer cursor on hover */
  font-weight: bold;
}

.table-form-button-container button.table-form-autofill-btn:hover {
  background-color: rgb(21, 115, 63);
}
