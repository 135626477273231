.home-container {
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-role-container {
  width: 92%;
  height: 80%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1.5vh 4vh;
  border-radius: 10px;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6vh;
}

.select-role-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 2.6vw; /* Use viewport width (vw) for responsive font size */
  line-height: 1.2; /* You can adjust this as needed */
  color: #000000;
  margin-bottom: 0vh; /* Use viewport height (vh) for responsive margin */
}

.select-role-message {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.7vw; /* Use viewport width (vw) for responsive font size */
  margin-bottom: 0vh; /* Use viewport height (vh) for responsive margin */
}


.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 100%; /* Ensure the button container takes up 100% width */
  max-width: 600px; /* Add a max-width to prevent excessive growth */
}

.select-role-btn {
  width: 100%; /* Set the button width to 100% of its parent container */
  height: 9vh;
  background-color: #003396;
  color: white;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5vw; /* Use a percentage for responsive font size */
  padding: 10px 20px; 
  border-radius: 6px;
  font-family: 'Inter';
  cursor: pointer; /* Move cursor style to the main style */
}

.select-role-btn:hover {
  background-color: #0c2e78;
}





@media screen and (max-width: 820px) {
  .select-role-title {
    font-size: 4.2vw; /* Increase font size for iPad */
  }

  .select-role-message {
    font-size: 3.2vw; /* Increase font size for iPad */
  }

  .select-role-btn {
    font-size: 4.0vw; /* Adjust font size for iPad */
  }


}
