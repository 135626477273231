.btn-legend {
  width: 40%;
  height: 30px;
  padding: 0px;
  margin-top: 5px;
  background-color: #0d56e9;
  font-weight: 600;
  border: solid 2px black;
  border-radius: 10px;
  color: white;
}

.btn-legend:hover {
  background-color: #094bcf;
  cursor: pointer;
}
.btn-legend:active {
  background-color: #003396;
}

#container-legend {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: solid 1px black;
  background-color: white;
}

#title-legend {
  /*
            scales font size with viewport width (range is 700px to 1900px). calculated with tool provided by https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
          */
  font-size: clamp(0.9rem, 0.3783rem + 1.113vw, 1.7rem);
  font-weight: 700;
  text-decoration-line: underline;
}

#container-btn {
  padding: 0px 15px 20px 15px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 5px;
  justify-content: space-around;
  align-items: end;
  align-self: center;
  font-weight: 600;
  font-size: clamp(0.6rem, 0.013rem + 1.2522vw, 1.5rem);
}

.shape-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#separator {
  grid-column: 5;
  background: gray; /* Color of the line */
  width: 2px; /* Width of the line */
  height: 100%; /* Making sure the line spans the full height */
  align-self: stretch; /* Stretching the line vertically */
  margin-left: calc(
    50%
  ); /* Pushing the line to the halfway point of the gap. Adjust if the gap size changes */
}

.pain-type {
  color: red;
  justify-self: right;
}

.pain-location {
  color: orange;
  justify-self: right;
  font-weight: 700;
}
