.doctor-template-container {
  align-items: center;
  margin: 30px 25px;
}

.doctor-template-back-button-container {
  margin-bottom: 25px;
}

.doctor-template-back-button {
  background-color: #003396;
  color: white;
  border: none;
  padding: 7px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
}

.doctor-template-back-button::before {
  content: "<";
  position: absolute;
  left: 7px;
}

.doctor-template-back-button span {
  margin-left: left;
}

.doctor-template-info-actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.doctor-template-gen-temp {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  height: 100%;
}

.doctor-template-gen-temp-text {
  font-size: 24px;
  color: black;
  margin: 0;
  margin-bottom: 3px;
}

.doctor-template-name {
  font-size: 14px;
  color: black;
  margin: 0;
}

.doctor-template-actions-button {
  background-color: #003396;
  color: white;
  border: none;
  padding: 8px 45px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.doctor-template-actions-menu {
  position: absolute;
  background-color: #d9d9d9;
  padding: 5px 25px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  top: 180px;
  right: 25px;
  width: 200px;
  margin-top: 5px;
  cursor: pointer;
  border: 1px solid black; /* Add a black border */
}

.doctor-template-action-option {
  margin: 5px 0;
}

.doctor-template-action-option:hover {
  text-decoration: underline;
}

.doctor-template-generated-template-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
}

.doctor-template-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.doctor-template-download-doc-button {
  width: 180px;
  height: 36px;
  background-color: #0f4699;
  color: white;
  border: none;
  padding: 8px 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  margin-right: 10px;
}

.doctor-template-finish-button {
  width: 180px;
  height: 36px;
  background-color: #ffaa00;
  color: white;
  border: 1px solid black;
  padding: 8px 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
}

.actions-container {
  display: flex;
  align-items: center;
}

.doctor-template-actions-button {
  background-color: #003396;
  color: white;
  border: none;
  padding: 8px 45px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 10px;
}

