.historian-patient-submission-setup-container {
    height: 92vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .historian-patient-submission-small-rectangle {
    position: relative;
    width: 90.3%;
    height: 10%;
    background-color: #003396;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0px 0px;
    margin-top: 4vh;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    padding-left: 50px;
    font-size: 2.5vw;
    font-weight: bold;
  }
  
  .historian-patient-submission-big-rectangle {
    top: -4.5vh;
    position: relative;
    width: 90.3%;
    height: 82%;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .historian-patient-submission-search-bar-container {
    width: 93%;
    padding: 2.5vh;
    margin-top: 1vh;
  }
  
  .historian-patient-submission-search-bar {
    width: 100%;
    height: 7vh;
    border: 2px solid #9f9f9f;
    border-radius: 10px;
    font-size: 1.5vw;
    padding: 0 1.2vh;
  }
  
  .historian-patient-submission-inner-rectangle {
    width: 90%;
    height: 70%;
    border-radius: 10px;
    overflow: auto;
    border: 2px solid #9f9f9f;
  }
  
  .historian-patient-submission-inner-rectangle table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .historian-patient-submission-inner-rectangle th,
  .historian-patient-submission-inner-rectangle td {
    padding: 1vh 1vh;
    text-align: left;
    font-size: 1.3vw;
    border-bottom: 1px solid #ddd;
  }
  
  .historian-patient-submission-inner-rectangle tr:last-child td {
    border-bottom: none;
  }
  
  .historian-patient-submission-tab-header {
    display: flex;
    width: 100%;
    margin: 0 auto;
    font-size: 1.3vw;
  }
  
  .historian-patient-submission-tab {
    flex: 1;
    background-color: rgba(87, 130, 214, 0.717);
    text-align: center;
    padding: 0.7vw;
    cursor: pointer;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    color: white;
  }
  
  .historian-patient-submission-tab:last-child {
    border-right: none;
  }
  
  .historian-patient-submission-tab.active {
    background-color: darkblue;
    color: white;
    font-weight: bold;
  }
  
  .historian-patient-submission-link {
    color: #003396;
  }
  
  .historian-patient-submission-action-button {
    margin-right: 0.5vh;
    margin-left: 0.5vh;
    padding: 0.6vh 1vh;
    font-size: 1vw;
    border: none;
    background-color: #003396;
    color: white;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .historian-patient-submission-action-button:hover {
    background-color: darkblue;
  }

  @media screen and (max-width: 820px) {
    .historian-patient-submission-search-bar {
      font-size: 3vw;
    }

    .historian-patient-submission-small-rectangle {
      font-size: 4vw;
    }

    .historian-patient-submission-tab-header {
      font-size: 2.6vw;
    }
    
    .historian-patient-submission-inner-rectangle th,
    .historian-patient-submission-inner-rectangle td {
      font-size: 2.3vw;
    }
    
    .historian-patient-submission-inner-rectangle tr:last-child td {
      border-bottom: none;
    }

    .historian-patient-submission-action-button {
      font-size: 1.5vw;
    }

    .historian-patient-submission-search-bar-container {
      width: 97%;
    }
  }