.question {
  padding: 7px;
  margin: 0px;
}
.question-even {
  background-color: #dbe1eb;
}
.question-odd {
  background-color: #ffffff;
}

.button-note {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 7px;
  border: none;
  border-radius: 3px;
  background-color: rgb(68, 107, 249);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  color: white;
  font-weight: 600;
}
.button-note:hover {
  background-color: rgb(44, 86, 237);
}
.button-note:active {
  background-color: rgb(36, 75, 217);
  transform: translate(4px, 4px);
  box-shadow: none;
}
.button-box-array {
  width: 50%;
  justify-self: end;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 7px;
  border: none;
  border-radius: 3px;
  background-color: rgb(68, 107, 249);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  color: white;
  font-weight: 600;
}
.button-box-array:hover {
  background-color: rgb(44, 86, 237);
}
.button-box-array:active {
  background-color: rgb(36, 75, 217);
  transform: translate(4px, 4px);
  box-shadow: none;
}
.textarea-note {
  border: 2px solid #9f9f9f;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 80px;
  font-size: 18px;
  padding-left: 10px;
}

.input-box {
  border: 2px solid #9f9f9f;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 40px;
  font-size: 18px;
  padding-left: 10px;
}
.input-box:invalid {
  border: 2px solid red;
}

.input-radio-grid-inline {
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
}
.input-radio-grid {
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
}
.input-radio-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5px;
}
.input-radio-container-inline {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.input-radio[type="radio"] {
  border: 1px solid red;
  min-width: 1.9rem;
  min-height: 1.9rem;
}
.input-radio[type="checkbox"] {
  border: 0px;
  min-width: 1.9rem;
  min-height: 1.9rem;
}
.input-radio:invalid {
  border: 2px solid #e60000 !important;
}

.error-text {
  color: #e60000 !important;
}

.error-input {
  border: 2px solid #e60000 !important;
}
.error-input:focus {
  outline: none !important;
  border: 2px solid red !important;
}
