.delete-confirmation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .delete-confirmation-content {
    background-color: white;
    padding: 35px;
    border-radius: 10px;
    text-align: center;
  }
  
  .delete-confirmation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .delete-confirmation-buttons button {
    margin: 0 10px;
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    background-color: grey;
    color: white;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .delete-confirmation-buttons button.delete-button {
    background-color: rgb(189, 5, 5);
  }