#container-drawing-component {
  background-color: #d5d5d5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

#header-drawing {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

#section-drawing {
  width: 85%;
  aspect-ratio: 16 / 9;
  display: grid;
  column-gap: 10px;
}

#container-canvas {
  grid-row: 2;
  height: 100%;
  border: solid 1px black;
  background-color: white;
}
